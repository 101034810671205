<template>
    <div :class="{ 'sharo-modal-container': true, 'active-modal': value }">
        <div class="sharo-modal">
            <div class="modal-top-info">
							<span class="modal-title">{{ title }}</span>
							<div @click="onClose" class="close-modal icon-hoverable">
								<font-awesome-icon style="font-size: 23px; color: rgb(100, 100, 100)" class="icon-hoverable" :icon="['fas', 'times']" />
							</div>
						</div>

						<div class="modal-content">
							<slot></slot>
						</div>
        </div>
    </div>
</template>

<script>
export default {
	props: {
		value: { type: Boolean, default: false },
		title: { type: String, default: '' }
	},

  data() {
    return {

		};
  },

  computed: {
    
  },

  methods: {
   onClose() {
		 this.$emit('input', !this.value);
	 },

	 onValueChange(val) {
		//  If turned on
		//  if (val) {
		// 	 document.body.classList.add('no-scroll');
		//  } else {
		// 	 document.body.classList.remove('no-scroll');
		//  }
	 }
  },

	mounted() {
		this.onValueChange(this.value);
	},
	
	watch: {
		value(val) {
			this.onValueChange(this.value);
		}
	}
};
</script>


<style lang="scss" scoped>
    @import './modal.scss';
</style>